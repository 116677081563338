<template>
  <div v-if="!loading">
    <div class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="flex flex-col pb-10 mx-auto mb-10">
        <div class="inline-flex justify-center flex-shrink-0 h-96">
          <img
            class="object-cover object-center w-full h-full rounded"
            :src="
              getBaseUrl() +
              department.attributes.departmentImage.data.attributes.url
            "
            alt="amc"
          />
        </div>
        <div class="flex-grow mt-6 sm:text-left">
          <div class="mb-2 text-2xl font-bold">
            {{ department.attributes.title }}
          </div>
          <p class="mt-3 text-base leading-relaxed">
            <VueShowdown :markdown="department.attributes.departmentContent" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img :src="loadingImage" class="w-64 m-auto" />
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "SelectedDepartmentDetail",
  props: ["id"],
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchDepartmentById(this.id);
  },

  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchDepartmentById"]),
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      department: (state) => state.LanguageStore.department,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchDepartmentById(this.id);
    },
  },
};
</script>

<style scoped></style>
