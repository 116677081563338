var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('slider',{attrs:{"animation":"fade","autoplay":true,"duration":5000,"speed":1000,"stopOnHover":true,"height":500 + 'px'}},_vm._l((_vm.sliderImages),function(sliderImage){return _c('slider-item',{key:sliderImage.id,style:({
        backgroundImage:
          'url(' +
          _vm.getBaseUrl() +
          sliderImage.attributes.sliderImage.data.attributes.url +
          ')',
        'object fit': 'cover',
        'background-repeat': 'no-repeat',
        'z-index': '2',
        'background-size': 'cover',
        'background-position': 'center',
        position: 'relative',
      })},[_c('div',{staticClass:"left-0 flex flex-col items-center justify-center h-full font-sans text-gray-200 bg-black md:items-start md:justify-end bg-opacity-40"},[_c('div',{staticClass:"p-4 mx-8 text-center md:pb-32 md:pl-32"},[_c('p',{staticClass:"text-4xl font-bold text-gray-200 md:text-6xl"},[_vm._v(" "+_vm._s(sliderImage.attributes.sliderTitle)+" ")]),_c('p',{staticClass:"text-xl text-gray-100"},[_vm._v(" "+_vm._s(sliderImage.attributes.sliderContent)+" ")])])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }