<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div class="px-5 py-20">
      <div v-if="!loading">
        <div class="flex flex-wrap w-full mb-10">
          <div class="w-full mb-6 lg:w-1/2 lg:mb-0">
            <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
              {{ $t("services") }}
            </h1>
            <div class="w-20 h-1 rounded bg-primary-green"></div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-6 pb-20 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
        >
          <div
            class="overflow-hidden text-gray-600 rounded-sm"
            v-for="department in departments"
            :key="department.id"
          >
            <div class="relative pb-1/3">
              <img
                class="absolute object-cover w-full h-full"
                :src="
                  getBaseUrl() +
                  department.attributes.departmentImage.data.attributes.url
                "
                :alt="department.attributes.title"
              />
            </div>
            <div class="py-4">
              <div class="mb-2 text-xl font-bold">
                {{ department.attributes.title }}
              </div>
              <p
                class="leading-relaxed"
                :class="{
                  'line-clamp-5':
                    department.attributes.departmentContent.trim().split(/\s+/)
                      .length > 36
                      ? true
                      : false,
                }"
              >
                <VueShowdown
                  :markdown="department.attributes.departmentContent"
                />
              </p>
              <router-link
                v-if="
                  department.attributes.departmentContent.trim().split(/\s+/)
                    .length > 36
                "
                @click.native="scrollToTop"
                :to="{
                  name: 'SelectedDepartmentDetail',
                  params: {
                    id: department.id,
                  },
                }"
                class="inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange"
              >
                ...{{ $t("read-more") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Departments",
  data() {
    return {
      more: false,
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchDepartments();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchDepartments"]),
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      departments: (state) => state.LanguageStore.departments,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchDepartments();
    },
  },
};
</script>

<style></style>
