import DataService from "@/services/DataService.js";
import { baseUrl } from "@/Api";

export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem("lang") || "en",
    loading: true,
    doctors: [],
    about: [],
    departments: [],
    department: [],
    sliderImages: [],
    hospitals: [],
    communities: [],
    affiliates: [],
    ourValues: [],
    vision: [],
    mission: [],
    footers: [],
    video: [],
    news: [],
    singleNews: [],
    principlesOfDevelopment: [],
  },
  mutations: {
    SET_LANG(state, lang) {
      state.lang = lang;
    },
    SET_DOCTORS(state, doctors) {
      state.doctors = doctors;
    },
    SET_ABOUT(state, about) {
      state.about = about;
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments;
    },
    SET_DEPARTMENT(state, department) {
      state.department = department;
    },
    SET_SLIDER_IMAGES(state, sliderImages) {
      state.sliderImages = sliderImages;
    },
    SET_HOSPITALS(state, hospitals) {
      state.hospitals = hospitals;
    },
    SET_COMMUNITIES(state, communities) {
      state.communities = communities;
    },
    SET_AFFILIATES(state, affiliates) {
      state.affiliates = affiliates;
    },
    SET_OUR_VALUES(state, ourValues) {
      state.ourValues = ourValues;
    },
    SET_VISION(state, vision) {
      state.vision = vision;
    },
    SET_MISSION(state, mission) {
      state.mission = mission;
    },
    SET_FOOTER(state, footer) {
      state.footer = footer;
    },
    SET_VIDEO(state, video) {
      state.video = video;
    },
    SET_NEWS(state, news) {
      state.news = news;
    },
    SET_SINGLE_NEWS(state, singleNews) {
      state.singleNews = singleNews;
    },
    SET_PRINCIPLES_OF_DEVELOPEMENT(state, principlesOfDevelopment) {
      state.principlesOfDevelopment = principlesOfDevelopment;
    },
  },
  actions: {
    changeLanguage({ commit }, lang) {
      commit("SET_LANG", lang);
    },
    fetchDoctors({ commit, state }) {
      state.loading = true;
      DataService.getDoctors(state.lang)
        .then((response) => {
          commit(
            "SET_DOCTORS",
            response.data.data.sort(
              (a, b) => a.attributes.rank - b.attributes.rank
            )
          );
          state.loading = false;
        })
        .catch((error) => {
          console.log("There was a problem fetching doctors:" + error.message);
        });
    },
    fetchAbout({ commit, state }) {
      state.loading = true;
      DataService.getAbout(state.lang)
        .then((response) => {
          commit("SET_ABOUT", response.data.data);
          state.loading = false;
        })
        .catch((error) => {
          console.log("There was a problem fetching about:" + error.message);
        });
    },
    fetchDepartments({ commit, state }) {
      state.loading = true;
      DataService.getDepartments(state.lang)
        .then((response) => {
          commit("SET_DEPARTMENTS", response.data.data);
          state.loading = false;
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching departments:" + error.message
          );
        });
    },
    fetchDepartmentById({ commit, state, getters }, id) {
      state.loading = true;
      let department = getters.department(id);
      if (department) {
        commit("SET_DEPARTMENT", department);
        state.loading = false;
      } else {
        DataService.getDepartmentById(state.lang, id)
          .then((response) => {
            commit("SET_DEPARTMENT", response.data.data);
            state.loading = false;
          })
          .catch((error) => {
            console.log(
              "There was a problem fetching department:" + error.message
            );
          });
      }
    },
    fetchSliderImages({ commit, state }) {
      DataService.getSliderImages(state.lang)
        .then((response) => {
          commit("SET_SLIDER_IMAGES", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching slider images:" + error.message
          );
        });
    },
    fetchHospitals({ commit, state }) {
      state.loading = true;
      DataService.getHospitals(state.lang)
        .then((response) => {
          commit("SET_HOSPITALS", response.data.data);
          state.loading = false;
        })
        .catch((error) => {
          console.log("There was a problem fetching hospital:" + error.message);
        });
    },
    fetchCommunities({ commit, state }) {
      state.loading = true;
      DataService.getCommunities(state.lang)
        .then((response) => {
          commit("SET_COMMUNITIES", response.data.data);
          state.loading = false;
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching community:" + error.message
          );
        });
    },
    fetchAffiliates({ commit, state }) {
      DataService.getAffiliates(state.lang)
        .then((response) => {
          commit("SET_AFFILIATES", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching affiliates:" + error.message
          );
        });
    },
    fetchOurValues({ commit, state }) {
      DataService.getOurValues(state.lang)
        .then((response) => {
          commit("SET_OUR_VALUES", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching values:" + error.message);
        });
    },
    fetchVision({ commit, state }) {
      DataService.getVision(state.lang)
        .then((response) => {
          commit("SET_VISION", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching vision:" + error.message);
        });
    },
    fetchMission({ commit, state }) {
      DataService.getMission(state.lang)
        .then((response) => {
          commit("SET_MISSION", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching mission:" + error.message);
        });
    },
    fetchFooters({ commit, state }) {
      DataService.getFooters(state.lang)
        .then((response) => {
          commit("SET_FOOTERS", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching footers:" + error.message);
        });
    },
    fetchVideo({ commit, state }) {
      DataService.getVideo(state.lang)
        .then((response) => {
          commit("SET_VIDEO", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching video:" + error.message);
        });
    },
    fetchNews({ commit, state }) {
      DataService.getNews(state.lang)
        .then((response) => {
          commit(
            "SET_NEWS",
            response.data.data
              .slice()
              .sort(
                (a, b) =>
                  new Date(b.attributes.publishDate) -
                  new Date(a.attributes.publishDate)
              )
          );
          state.loading = false;
        })
        .catch((error) => {
          console.log("There was a problem fetching news:" + error.message);
        });
    },
    fetchNewsById({ commit, state, getters }, id) {
      state.loading = true;
      let singleNews = getters.getLatestNews(id);
      if (singleNews) {
        commit("SET_SINGLE_NEWS", singleNews);
        state.loading = false;
      } else {
        DataService.getNewsById(state.lang, id)
          .then((response) => {
            commit("SET_SINGLE_NEWS", response.data.data);
            state.loading = false;
          })
          .catch((error) => {
            console.log("There was a problem fetching news:" + error.message);
          });
      }
    },
    fetchPrinciplesOfDevelopments({ commit, state }) {
      DataService.getPrinciplesOfDevelopment(state.lang)
        .then((response) => {
          commit("SET_PRINCIPLES_OF_DEVELOPEMENT", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching principles:" + error.message
          );
        });
    },
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    getLatestNews: (state) => (id) => {
      return state.news.find((singleNews) => singleNews.id === id);
    },
    department: (state) => (id) => {
      return state.departments.find((department) => department.id === id);
    },
  },
};
