var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-6xl px-5 py-20 mx-auto text-gray-600"},[(!_vm.loading)?_c('div',[_c('div',{staticClass:"flex flex-wrap w-full mb-10"},[_c('div',{staticClass:"w-full mb-6 lg:w-1/2 lg:mb-0"},[_c('h1',{staticClass:"mb-2 text-2xl font-bold text-gray-900 sm:text-3xl"},[_vm._v(" "+_vm._s(_vm.$t("our-team"))+" ")]),_c('div',{staticClass:"w-20 h-1 rounded bg-primary-green"})])]),_vm._l((_vm.doctors),function(doctor){return _c('div',{key:doctor.id,staticClass:"flex flex-wrap mb-16"},[_c('div',{staticClass:"flex flex-col justify-center h-full md:flex-row"},[_c('img',{staticClass:"flex-shrink-0 object-cover object-center h-64 mb-4 rounded-sm w-80 md:w-56",attrs:{"alt":"team","src":_vm.getBaseUrl() + doctor.attributes.image.data.attributes.url}}),_c('div',{staticClass:"flex-grow mt-2 md:pl-8"},[_c('h2',{staticClass:"text-xl font-bold text-gray-900"},[_vm._v(" "+_vm._s(doctor.attributes.name)+" ")]),_c('h3',{staticClass:"mb-3 font-semibold"},[_vm._v(" "+_vm._s(doctor.attributes.specialization)+" ")]),_c('div',{staticClass:"mb-4 leading-relaxed"},[_c('VueShowdown',{attrs:{"markdown":`${
                doctor.attributes.profile !== undefined
                  ? doctor.attributes.profile
                  : ''
              }`}})],1),(
              doctor.attributes.name === 'Dr. Samuel Hailu' &&
              doctor.id === '619216c6881dc80016ac9a3f' &&
              doctor.attributes.department.id === '6163e4ad55e2f500160c0aae'
            )?_c('a',{staticClass:"inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange",attrs:{"href":"https://drsamuelhailu.com/booking/"}},[_vm._v(" "+_vm._s(_vm.$t("book-an-appointment"))+" ")]):_c('a',{staticClass:"inline-flex items-center mt-3 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer text-primary-orange hover:border-primary-orange",attrs:{"href":"/appointment"}},[_vm._v(" "+_vm._s(_vm.$t("book-an-appointment"))+" ")])])])])})],2):_c('div',[_c('img',{staticClass:"w-64 m-auto",attrs:{"src":_vm.loadingImage}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }