<template>
  <div>
    <nav class="px-5 py-4 mx-auto bg-white border-b">
      <!--  container md:px-0 -->
      <div class="lg:flex lg:flex-wrap lg:items-center">
        <div class="flex items-center justify-between">
          <router-link to="/">
            <div class="flex items-center">
              <img src="@/assets/amc_logo.png" alt="amc logo" class="h-16" />
            </div>
          </router-link>
          <button
            @click="toggle"
            class="px-3 py-1 text-gray-600 border border-gray-600 border-solid rounded opacity-50 hover:opacity-75 lg:hidden"
            id="navbar-toggle"
          >
            <svg
              fill="none"
              stroke="currentColor"
              class="w-6 h-6"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <div
          :class="open ? 'flex' : 'hidden'"
          class="flex-col mt-3 lg:flex lg:flex-row lg:ml-auto lg:mt-0"
          id="navbar-collapse"
        >
          <router-link
            v-for="item in navs"
            :key="item.name"
            @click.native="scrollToTop"
            :to="item.route"
            class="p-2 text-sm font-semibold text-gray-700 uppercase transition-colors duration-300 lg:px-3 md:mx-2 hover:text-primary-green"
          >
            {{ $t(`nav.${item.name}`) }}
          </router-link>
        </div>
        <LocaleSwitcher />
      </div>
    </nav>
  </div>
</template>

<script>
import LocaleSwitcher from "../LocaleSwitcher.vue";
export default {
  name: "Header",
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      navs: [
        {
          name: "home",
          route: "/",
        },
        {
          name: "services",
          route: "/departments",
        },
        {
          name: "doctors",
          route: "/doctors",
        },
        {
          name: "news",
          route: "/news",
        },
        {
          name: "about-us",
          route: "/about-us",
        },
        {
          name: "contact-us",
          route: "/contact-us",
        },
        {
          name: "reviews",
          route: "/reviews",
        },
        {
          name: "vacancy",
          route: "/vacancy",
        },
        {
          name: "make-appointment",
          route: "/appointment",
        },
      ],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
