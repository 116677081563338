<template>
  <div class="py-20">
    <div class="flex flex-wrap md:flex-nowrap">
      <div class="flex flex-col flex-shrink-0 mb-6 md:w-64 md:mb-0">
        <span class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
          {{ vision.attributes.Title }}
        </span>
      </div>
      <div class="md:flex-grow">
        <p class="leading-relaxed">
          <VueShowdown :markdown="`${vision.attributes.Content}`" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Vission",
  data() {
    return {
      error: null,
    };
  },
  created() {
    this.fetchVision();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchVision"]),
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      vision: (state) => state.LanguageStore.vision,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchVision();
    },
  },
};
</script>

<style></style>
