<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <div class="px-5">
      <hr />
    </div>
    <section class="px-5 py-20">
      <div class="w-full mb-10 lg:w-1/2">
        <h2 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
          {{ $t("affiliate-companies") }}
        </h2>
        <div class="w-20 h-1 rounded bg-primary-green"></div>
      </div>
      <div
        class="grid grid-cols-1 gap-5 p-10 mx-auto bg-gray-100 md:grid-cols-2 lg:grid-cols-3"
      >
        <div
          class="flex items-center justify-center"
          v-for="affiliate in affiliates"
          :key="affiliate.id"
        >
          <a :href="affiliate.attributes.url" target="_blank">
            <img
              class="h-36"
              :src="
                getBaseUrl() + affiliate.attributes.image.data.attributes.url
              "
              :alt="affiliate.attributes.url"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Affiliate",
  created() {
    this.$store.dispatch("LanguageStore/fetchAffiliates");
  },

  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      affiliates: (state) => state.LanguageStore.affiliates,
    }),
  },
  watch: {
    async lang() {
      await this.$store.dispatch("LanguageStore/fetchAffiliates");
    },
  },
};
</script>
<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
