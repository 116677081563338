<template>
  <div>
    <Hero></Hero>
    <Our-community></Our-community>
    <OurHospitals></OurHospitals>
    <Video></Video>
    <Affiliate></Affiliate>
    <LatestNews></LatestNews>
    <Subscribe></Subscribe>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import OurCommunity from "@/components/OurCommunity.vue";
import OurHospitals from "@/components/OurHospitals.vue";
import Video from "@/components/Video.vue";
import Affiliate from "@/components/Affiliate.vue";
import LatestNews from "@/components/LatestNews.vue";
import Subscribe from "@/components/Subscribe.vue";

export default {
  name: "Home",
  components: {
    OurCommunity,
    OurHospitals,
    Hero,
    Video,
    Affiliate,
    Subscribe,
    LatestNews,
  },
};
</script>
<style></style>
