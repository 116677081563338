<template>
  <section class="max-w-6xl mx-auto text-gray-600">
    <div v-if="!loading">
      <div class="px-5 py-20 mx-auto">
        <div v-for="nws in news" :key="nws.newsTitle">
          <SingleNewsDisplay :news="nws" />
        </div>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../Api";
import SingleNewsDisplay from "../components/SingleNewsDisplay.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "News",
  components: { SingleNewsDisplay },
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchNews();
  },

  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchNews"]),
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      news: (state) => state.LanguageStore.news,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchNews();
    },
  },
};
</script>

<style></style>
