<template>
  <section class="max-w-6xl px-5 py-24 mx-auto text-gray-600">
    <div class="py-10 bg-gray-100">
      <div class="flex flex-col w-full mb-4 text-center">
        <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
          {{ $t("subscribe-to-our-newsletter") }}
        </h1>
        <p class="text-lg">
          {{ $t("stay-up-to-date-with-our-latest-news-and-services") }}
        </p>
      </div>
      <div class="max-w-4xl px-5 mx-auto">
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div
              class="w-full p-2 mx-auto mb-3 text-center text-white bg-red-800 rounded"
              v-if="error"
            >
              Please fill out the form below
            </div>
            <div class="flex flex-col flex-wrap sm:flex-row">
              <div class="flex-1 w-full p-2 mb-2 md:w-1/3">
                <ValidationProvider
                  name="Full name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="fName"
                    v-model="fName"
                    name="fullName"
                    :placeholder="$t('full-name')"
                    class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="flex-1 w-full p-2 mb-2 md:w-1/3">
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    id="email"
                    v-model="email"
                    name="email"
                    :placeholder="$t('email')"
                    class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="p-2">
                <button
                  type="submit"
                  class="w-full px-4 py-2 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-primary-orange focus:outline-none hover:opacity-90"
                >
                  {{ $t("subscribe") }}
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  data() {
    return {
      fName: "",
      email: "",
      error: false,
    };
  },
  methods: {
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(`${baseUrl}/api/subscriptions`, {
              data: {
                fullName: this.fName,
                email: this.email,
              },
            })
            .then((response) => {
              this.$toast.success("You have subscribed successfully");
            })
            .catch((error) => {
              console.log("Error in subscriptions", error.message);
            });
        } catch (error) {
          this.error = error;
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style></style>
