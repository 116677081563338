<template>
  <div v-if="!loading">
    <div class="max-w-6xl px-5 py-20 mx-auto text-gray-600">
      <div class="pb-10 mx-auto mb-10 lg:w-4/5 sm:flex-row">
        <div class="mb-6">
          <img
            class="object-cover object-center w-full h-full rounded lg:w-5/6 lg:h-96"
            :src="getBaseUrl() + news.attributes.newsImage.data.attributes.url"
            alt="samaritan"
          />
        </div>
        <div class="mt-6 sm:text-left sm:mt-0">
          <h2 class="text-xl font-semibold text-gray-900 title-font">
            {{ news.attributes.newsTitle }}
          </h2>
          <span class="text-sm text-gray-400">{{
            news.attributes.publishedDate
          }}</span>
          <p class="mt-3 text-base leading-relaxed">
            <VueShowdown :markdown="news.attributes.newsContent" />
          </p>
          <p v-if="news.attributes.newsSource" class="mt-2">
            <span class="italic font-medium">Source link: </span>
            <a
              :href="news.attributes.newsSource"
              target="_blank"
              class="border-b border-transparent hover:text-primary-orange hover:border-primary-orange"
              >{{ news.attributes.newsSource }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img :src="loadingImage" class="w-64 m-auto" />
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "SelectedLatestNewsDetail",
  props: ["id"],
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchNewsById(this.id);
  },

  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchNewsById"]),
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      news: (state) => state.LanguageStore.singleNews,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchNewsById(this.id);
    },
  },
};
</script>

<style></style>
