<template>
  <div class="max-w-6xl mx-auto text-gray-600">
    <section>
      <div class="flex flex-col justify-center px-5 py-20 lg:flex-row">
        <img
          alt="team"
          :src="getBaseUrl() + hospital.attributes.image.data.attributes.url"
          class="flex-shrink-0 order-2 object-cover object-center mb-4 rounded-sm lg:w-1/2 lg:order-1"
        />
        <div class="flex-grow order-1 mb-10 lg:pl-8 lg:order-2">
          <h3
            class="mb-4 text-4xl font-bold title-font sm:text-5xl text-primary-orange"
          >
            {{ hospital.attributes.title }}
          </h3>
          <div class="text-lg leading-relaxed">
            <p>
              <VueShowdown :markdown="`${hospital.attributes.content}`" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "OurHospitals",
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchHospitals();
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      hospital: (state) => state.LanguageStore.hospitals,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.fetchHospitals();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchHospitals"]),
  },
};
</script>

<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
