<template>
  <section>
    <div v-if="!loading" class="max-w-6xl mx-auto text-gray-600">
      <div class="px-5 py-20">
        <div class="w-full mb-10">
          <h1
            class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl title-font"
          >
            {{ about.attributes.aboutName }}
          </h1>
          <div class="w-20 h-1 rounded bg-primary-green"></div>
        </div>
        <div>
          <p class="text-lg leading-relaxed">
            <VueShowdown :markdown="`${about.attributes.content}`" />
          </p>
        </div>
      </div>
      <div class="px-5">
        <OurValues></OurValues>
        <Principles></Principles>
        <Vission></Vission>
        <Mission></Mission>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>
<script>
import OurValues from "@/components/OurValues.vue";
import Principles from "@/components/PrinciplesOfDevelopment.vue";
import Vission from "@/components/Vission.vue";
import Mission from "@/components/Mission.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "About",
  components: {
    OurValues,
    Principles,
    Vission,
    Mission,
  },
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchAbout();
  },
  methods: mapActions("LanguageStore", ["fetchAbout"]),
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      about: (state) => state.LanguageStore.about,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchAbout();
    },
  },
};
</script>
