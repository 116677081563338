<template>
  <div>
    <div>
      <hr />
    </div>
    <section class="px-5 py-20 text-gray-600 md:px-0">
      <div class="w-full mb-10 lg:w-1/2">
        <h1
          class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl title-font"
        >
          {{ $t("principles-of-development") }}
        </h1>
        <div class="w-20 h-1 rounded bg-primary-green"></div>
      </div>
      <div
        class="grid grid-cols-1 gap-5 px-5 py-8 mx-auto text-gray-700 bg-gray-100 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div class="h-auto" v-for="principle in principles" :key="principle.id">
          <img
            :src="getBaseUrl() + principle.attributes.Image.data.attributes.url"
            :alt="principle.Title"
            class="h-24 mb-4"
          />
          <h2 class="mb-2 text-lg font-bold">
            {{ principle.attributes.Title }}
          </h2>
          <p class="leading-relaxed">
            <VueShowdown :markdown="`${principle.attributes.Description}`" />
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { baseUrl } from "../Api";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "Principles",
  data() {
    return {
      error: null,
    };
  },
  created() {
    this.fetchPrinciplesOfDevelopments();
  },

  methods: {
    ...mapActions("LanguageStore", ["fetchPrinciplesOfDevelopments"]),
    getBaseUrl() {
      return baseUrl;
    },
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      principles: (state) => state.LanguageStore.principlesOfDevelopment,
    }),
  },
  watch: {
    async lang() {
      await this.fetchPrinciplesOfDevelopments();
    },
  },
};
</script>
<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
