import axios from "axios";
import { baseUrl } from "../Api";

// A single Axios instance for our entire app
const apiClient = axios.create({
  // Base URL for all calls to use
  baseURL: baseUrl,
  withCredentials: false,
  // For authentication and configuration
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getDoctors(lang) {
    return apiClient.get(`api/doctors?locale=${lang}&populate=*`);
  },
  getAbout(lang) {
    return apiClient.get(`api/about?locale=${lang}`);
  },
  getDepartments(lang) {
    return apiClient.get(`api/departments?locale=${lang}&populate=*`);
  },
  getDepartmentById(lang, id) {
    return apiClient.get(`api/departments/${id}?locale=${lang}&populate=*`);
  },
  getSliderImages(lang) {
    return apiClient.get(`api/slider-images?locale=${lang}&populate=*`);
  },
  getHospitals(lang) {
    return apiClient.get(`api/our-hospital?locale=${lang}&populate=*`);
  },

  getCommunities(lang) {
    return apiClient.get(`api/our-community?locale=${lang}&populate=*`);
  },
  getAffiliates() {
    return apiClient.get(`api/affiliates?populate=*`);
  },
  getOurValues(lang) {
    return apiClient.get(`api/our-values?locale=${lang}&populate=*`);
  },
  getVision(lang) {
    return apiClient.get(`api/vision?locale=${lang}`);
  },
  getMission(lang) {
    return apiClient.get(`api/mission?locale=${lang}`);
  },
  getFooters(lang) {
    return apiClient.get(`api/footers?locale=${lang}`);
  },
  getVideo(lang) {
    return apiClient.get(`api/video?locale?&populate=*`);
  },
  getNews(lang) {
    return apiClient.get(`api/news?locale=${lang}&populate=*`);
  },
  getNewsById(lang, id) {
    return apiClient.get(`api/news/${id}?locale=${lang}&populate=*`);
  },
  getPrinciplesOfDevelopment(lang) {
    return apiClient.get(
      `api/principle-developments?locale=${lang}&populate=*`
    );
  },

  postEvent(event) {
    return apiClient.get(`/our-hospitals}`);
  },
};
