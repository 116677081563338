<template>
  <div>
    <div v-if="!loading" class="max-w-6xl mx-auto text-gray-600">
      <section>
        <div class="flex flex-col justify-center px-5 py-20 lg:flex-row">
          <div class="flex-grow mb-10 lg:mb-0">
            <h3 class="mb-4 text-4xl font-bold sm:text-5xl text-primary-orange">
              {{ community.attributes.title }}
            </h3>
            <div class="text-lg leading-relaxed">
              <p>
                <VueShowdown :markdown="`${community.attributes.content}`" />
              </p>
            </div>
          </div>
          <img
            alt="team"
            :src="getBaseUrl() + community.attributes.image.data.attributes.url"
            class="flex-shrink-0 object-cover object-center mb-4 rounded-sm lg:pl-8 lg:w-1/2"
          />
        </div>
      </section>
      <div class="px-5">
        <hr />
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "OurCommunity",
  data() {
    return {
      loadingImage: require("../assets/curve-Loading.gif"),
    };
  },
  created() {
    this.fetchCommunities();
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      community: (state) => state.LanguageStore.communities,
      loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchCommunities();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchCommunities"]),
  },
};
</script>

<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
