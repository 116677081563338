<template>
  <div>
    <div>
      <hr />
    </div>
    <section class="px-5 py-20 mx-auto text-gray-600 md:px-0">
      <div class="w-full mb-10 lg:w-1/2">
        <h1 class="mb-2 text-2xl font-bold text-gray-900 sm:text-3xl">
          {{ $t("our-values") }}
        </h1>
        <div class="w-20 h-1 rounded bg-primary-green"></div>
      </div>
      <div
        class="grid grid-cols-1 gap-5 px-8 py-8 mx-auto text-gray-700 bg-gray-100 rounded-md sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div class="h-64" v-for="value in ourValues" :key="value.id">
          <img
            :src="
              getBaseUrl() + value.attributes.valueImage.data.attributes.url
            "
            :alt="value.attributes.Title"
            class="h-24 mb-4"
          />
          <h2 class="mb-2 text-lg font-bold">
            {{ value.attributes.Title }}
          </h2>
          <p class="leading-relaxed">
            {{ value.attributes.Description }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { baseUrl } from "../Api";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "OurValues",

  created() {
    this.fetchOurValues();
  },

  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions("LanguageStore", ["fetchOurValues"]),
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
    }),
    ...mapState({
      ourValues: (state) => state.LanguageStore.ourValues,
      // loading: (state) => state.LanguageStore.loading,
    }),
  },
  watch: {
    async lang() {
      await this.fetchOurValues();
    },
  },
};
</script>
<style scoped>
hr {
  background: linear-gradient(217deg, #d76738, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #4aa350, rgba(0, 255, 0, 0) 70.71%);
  transition: width 0.35s;
  height: 1px;
  border-top-width: 0 !important;
}
</style>
